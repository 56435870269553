export const CONTRACTIONS = {
  "am not": "ain’t",
  "are not": "aren’t",
  "could have": "could’ve",
  "could not have": "couldn’t’ve",
  "could not": "couldn’t",
  "dare not": "daresn’t",
  "dared not": "daren’t",
  "did not": "didn’t",
  "do not": "don’t",
  "does not": "doesn’t",
  "everyone is": "everyone’s",
  "everything is": "everything’s",
  "fixing to": "finna",
  "give me": "gimme",
  "going to": "gonna",
  "got to": "gotta",
  "had not": "hadn’t",
  "has not": "hasn’t",
  "have not": "haven’t",
  "he had": "he’d",
  "he has": "he’s",
  "he have": "he’ve",
  "he is": "he’s",
  "he shall": "he’ll",
  "he will": "he’ll",
  "he would": "he’d",
  "how are": "how’re",
  "how did": "how’d",
  "how do you do": "howdy",
  "how do you fare": "howdy",
  "how does": "how’s",
  "how has": "how’s",
  "how is": "how’s",
  "how will": "how’ll",
  "how would": "how’d",
  "i am about to": "I’m’a",
  "i am going to": "I’m’o",
  "i am": "I’m",
  "i had": "I’d",
  "i have": "I’ve",
  "i shall": "I’ll",
  "i will": "I’ll",
  "i would": "I’d",
  "is not": "isn’t",
  "is, has, does, or us": "’s",
  "it has": "it’s",
  "it is": "it’s",
  "it shall": "it’ll",
  "it was": "’twas",
  "it will": "it’ll",
  "it would": "it’d",
  "let us": "let’s",
  "may have": "may’ve",
  "may not": "mayn’t",
  "might have": "might’ve",
  "might not": "mightn’t",
  "must have": "must’ve",
  "must not have": "mustn’t’ve",
  "must not": "mustn’t",
  "need not": "needn’t",
  "of the clock": "o’clock",
  "ought not": "oughtn’t",
  "shall not": "shan’t",
  "she had": "she’d",
  "she has": "she’s",
  "she is": "she’s",
  "she shall": "she’ll",
  "she will": "she’ll",
  "she would": "she’d",
  "should have": "should’ve",
  "should not have": "shouldn’t’ve",
  "should not": "shouldn’t",
  "so are": "so’re",
  "somebody has": "somebody’s",
  "somebody is": "somebody’s",
  "someone has": "someone’s",
  "someone is": "someone’s",
  "something has": "something’s",
  "something is": "something’s",
  "that are": "that’re",
  "that had": "that’d",
  "that has": "that’s",
  "that is": "that’s",
  "that shall": "that’ll",
  "that will": "that’ll",
  "that would": "that’d",
  "there are": "there’re",
  "there had": "there’d",
  "there has": "there’s",
  "there is": "there’s",
  "there shall": "there’ll",
  "there will": "there’ll",
  "there would": "there’d",
  "these are": "these’re",
  "they are": "they’re",
  "they had": "they’d",
  "they have": "they’ve",
  "they shall": "they’ll",
  "they were": "they’re",
  "they will": "they’ll",
  "they would": "they’d",
  "this has": "this’s",
  "this is": "this’s",
  "those are": "those’re",
  "to have": "to’ve",
  "was not": "wasn’t",
  "we are": "we’re",
  "we had": "we’d",
  "we have": "we’ve",
  "we will": "we’ll",
  "we would have": "we’d’ve",
  "we would": "we’d",
  "were not": "weren’t",
  "what all": "what’ll",
  "what are": "what’re",
  "what did": "what’d",
  "what does": "what’s",
  "what has": "what’s",
  "what have": "what’ve",
  "what is": "what’s",
  "what shall": "what’ll",
  "what were": "what’re",
  "what will": "what’ll",
  "when has": "when’s",
  "when is": "when’s",
  "where are": "where’re",
  "where did": "where’d",
  "where does": "where’s",
  "where has": "where’s",
  "where have": "where’ve",
  "where is": "where’s",
  "which has": "which’s",
  "which is": "which’s",
  "who are": "who’re",
  "who did": "who’d",
  "who does": "who’s",
  "who had": "who’d",
  "who has": "who’s",
  "who have": "who’ve",
  "who is": "who’s",
  "who shall": "who’ll",
  "who will": "who’ll",
  "who would have": "who’d’ve",
  "who would": "who’d",
  "why are": "why’re",
  "why did": "why’d",
  "why does": "why’s",
  "why has": "why’s",
  "why is": "why’s",
  "will not": "won’t",
  "would have": "would’ve",
  "would not": "wouldn’t",
  "you all would have": "y’all’d’ve",
  "you all": "y’all",
  "you are": "you’re",
  "you had": "you’d",
  "you have": "you’ve",
  "you shall": "you’ll",
  "you will": "you’ll",
  "you would": "you’d",
  because: "’cause",
  cannot: "can’t",
  madam: "ma’am",
  never: "ne’er",
  old: "ol’",
  over: "o’er"
};

type Contractable = keyof typeof CONTRACTIONS;

const isCapitalized = (value: string) => value[0] !== value[0].toLowerCase();

const capitalize = (value: string) => value[0].toUpperCase() + value.slice(1);

export const contract = (value: string) => {
  const contraction =
    CONTRACTIONS[value.toLowerCase() as Contractable] || value;
  return isCapitalized(value) ? capitalize(contraction) : contraction;
};

export const maybeContract = (value: string, probability: number = 0.66) =>
  Math.random() > probability ? contract(value) : value;
